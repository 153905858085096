<template>
  <v-card class="my-4" elevation="4">
    <v-card-title>
      <v-icon class="mr-2">mdi-cellphone-settings-variant</v-icon>
      Atualização inApp (sp-react-native-in-app-updates)
    </v-card-title>
    <v-card-text class="ma-2">
      <v-row no-gutters>
        <v-col cols="8" class="mr-4">
          <span class="pb-2">Modo atualização</span>
          <v-select v-model="updateMode" :items="updateModes" label="Forçar usuário atualizar (Não conseguirá utilizar na versão atual)" full-width outlined />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="dialog = true">
        <v-icon class="mr-1">sync</v-icon>
        Atualizar Modo
      </v-btn>
    </v-card-actions>
    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="submitUpdate" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { QUERY_CONFIGURATION, MUTATION_UPDATE_APP_MODEL } from '@/modules/accounts/graphql'

export default {
  components: {
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    updateMode: null,
    updateModes: ['FORCED', 'RECOMMENDED', 'NO_RECOMMENDED'],
    dialog: false,
    textDialog: 'Tem certeza que deseja atualizar as configurações?'
  }),
  apollo: {
    configuration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update(data) {
        this.updateMode = data.configuration.app_update_type
      },
      error() {
        this.$alert({
          alert_message: 'Erro ao buscar a configuração',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  },
  methods: {
    openConfirmationDialog() {
      this.dialog = true
    },
    async submitUpdate() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_MODEL,
          variables: {
            accountId: this.accountId,
            type: this.updateMode
          }
        })
        this.$alert({
          alert_message: 'Configurações atualizadas com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: 'Falha ao atualizar as configurações',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
    }
  }
}
</script>
